<template>
  <div class="manager-member-page">
    <div class="search-wrapper">
      <el-form
        :inline="true"
        :model="searchForm"
        class="search-form"
        size="small"
        ref="searchForm"
      >
        <el-form-item label="">
          <el-select v-model="searchForm.categoryId" placeholder="按类型筛选" clearable>
            <el-option :label="item.name" :value="item.id" v-for="item in categoryOptions" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input
            v-model="searchForm.name"
            placeholder="输入标签名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
            size="mini"
            class="green-btn"
            >查询</el-button
          >
          <el-button @click="handleClear" size="mini" class="green-plain-btn"
            >刷新</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table-wrapper">
      <div class="box-table">
        <el-table
          v-loading="pageLoading"
          :data="pageData"
          style="width: 100%"
          height="100%"
          @selection-change="selectionChange"
        >
          <!-- <el-table-column type="index" width="55" label="序号" align="center">
          </el-table-column> -->
          <el-table-column prop="name" label="标签名称" align="center">
          </el-table-column>
          <el-table-column prop="categoryName" label="类型名称" align="center">
          </el-table-column>
        </el-table>
      </div>

      <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageParams.total"
          :current-page="pageParams.currentPage"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      pageParams: {
        total: 0,
        currentPage: 1,
        pageNum: 1,
        pageSize: 10,
      },
      searchForm: {
        categoryId: "",
        name: "",
      },
      pageData: [],
      pageLoading: false,
      categoryOptions:[],
    };
  },
  created() {
    this.getPageData(true);
    this.getAllCategory();
  },
  methods: {
    handleSearch() {
      this.getPageData(true);
    },
    // 清空搜索
    handleClear() {
      this.searchForm.categoryId = "";
      this.searchForm.name = "";
      this.$refs.searchForm.resetFields();
      this.getPageData(true);
    },
    selectionChange(data) {
      let idList = [];
      if (data.length) {
        data.forEach((item) => {
          idList.push(item.id);
        });
        this.idList = idList;
      }
    },
    // 获取设计师列表
    getPageData(flag) {
      if (flag) {
        this.pageData = [];
        this.pageParams = {
          pageNum: 1,
          pageSize: 10,
          total: 0,
          currentPage: 0,
        };
      }
      let params = Object.assign({}, this.pageParams);
      params.categoryId = this.searchForm.categoryId;
      params.name = this.searchForm.name;
      this.pageLoading = true;
      this.$http
        .get("/com-tag-info/listPage", { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            if (res.data && res.data.records) {
              this.pageData = res.data.records;
              this.pageParams.total = res.data.total;
              this.pageParams.currentPage = res.data.current;
            }
          } else {
            this.$message.error("获取数据失败" + res.msg);
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
          this.$message.error("获取数据失败," + err);
        });
    },
    // 分页
    handlePageChange(current) {
      this.pageParams.currentPage = current;
      this.pageParams.pageNum = current;
      this.getPageData();
    },
    // 获取所有的类型
    getAllCategory(){
      this.$http.get('/com-tag-category/listAll').then(res => {
        if(res.code == this.SUCCESS_CODE){
          let data = res.data;
          this.categoryOptions = data;
          // this.categoryOptions = data.map(item => {
          //   return {
          //     label:item.name,
          //     value:item.id
          //   }
          // })
        }
      })
    },
  },
};
</script>
